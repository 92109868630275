import React, { useState } from 'react';
import { Grid, Button, TextField, Container, Typography } from '@material-ui/core';
import { createLead } from '../api/leads';
import { navigate } from "gatsby"


const Form = () => {
  const [formData, setFormData] = useState({
    nume: '',
    prenume: '',
    telefon: '',
    email: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(formData);

    const myFormElement = e.target;
    const formElementData = new FormData(myFormElement);

    if(window.grecaptcha)
    {
      console.log(grecaptcha.getResponse())
    }
    console.log(new URLSearchParams(formElementData).toString())
    
    const response = await fetch('/.netlify/functions/addLead', {
      method: 'POST',
      body: JSON.stringify({
        firstName: formData.prenume,
        lastName: formData.nume,
        phone: formData.telefon
      })
    })
        .then(response => response.json())
        .catch(error => console.error('Error:', error));
    
    if(response && response.success){
        setFormData({
            nume: '',
            prenume: '',
            telefon: '',
            email: '',
        });
        navigate("/thank-you");
    }

    console.log("API:",response);
  };

  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <form onSubmit={handleSubmit}
              data-netlify-recaptcha="true"
              data-netlify="true"
              name="addLeadForm">
          <input type="hidden" name="form-name" value="addLeadForm" />
          <TextField
            label="Nume"
            name="nume"
            value={formData.nume}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prenume"
            name="prenume"
            value={formData.prenume}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Telefon"
            name="telefon"
            value={formData.telefon}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="email"
          />
          <div data-netlify-recaptcha="true"></div>
          <Button type="submit" variant="contained" color="primary">
            Iau creditul
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}
export default Form
